import { checkAuth } from '@utilities/checkAuth';

interface checkIsPlusUserProps {
        accountTier: 'STANDARD' | 'PLUS',
        paymentExpiry: Date,
        founderState: string
}

export const checkIsPlusUser = async (accountInfo : checkIsPlusUserProps) => {
  if (typeof window === 'undefined') {
    // Covers incomplete/partial state.
    return false;
  }

  // If the user is unauthenticated we know they aren't a plus user.
  if (!await checkAuth()) {
    return false;
  }

  if (!accountInfo) {
    return false;
  }
      
  const { paymentExpiry } = accountInfo;

  // If paymentExpiry is not epoch 0, this user has had a plus subscription
  return new Date(paymentExpiry).getTime() !== 0;
};
