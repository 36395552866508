import { Auth } from '@aws-amplify/auth';

import { checkIsPlusUser } from '@utilities/checkIsPlusUser';
import { getProfiles } from '@utilities/getProfiles';
import { checkAuth } from '@utilities/checkAuth';
import { getIdTokenClaims } from '@utilities/getIdTokenClaims';

import { featureFlagConnection } from '@utilities/featureFlagging';

const SSR = typeof window === 'undefined';
export const syncUserSetup = async () => {

  let userAccount = {
    identityId: ''
  };
  let defaultPlusMemberCheck = false;
  let idTokenClaims = {};
  let profileName = 'Name';
  let profileTrade = 'Title';
  let phoneNumber = '';
  let profileId = '';
  let accountTier: 'STANDARD' | 'PLUS' = 'STANDARD';
  let paymentPlatformCustomerId = '';
  let paymentPlatform: 'NONE' | 'STRIPE' | 'APPLE' | 'GOOGLE' = 'NONE';
  let paymentExpiry =  new Date(0);
  let founderState = false;
  let sharedUserId = '';
  let isAuth = false;
  let email = '';
  let identityId = '';
  let inactiveReason: 'NOT_APPLICABLE'| 'CANCELED' | 'EXPIRED' | 'PAUSED' = 'NOT_APPLICABLE';

  if (!SSR) {
    isAuth = await checkAuth();

    if (!isAuth) {
      return {
        accountTier,
        paymentExpiry,
        founderState,
        profileId,
        profileName,
        profileTrade,
        paymentPlatformCustomerId,
        paymentPlatform,
        phoneNumber,
        userAccount,
        defaultPlusMemberCheck,
        idTokenClaims,
        sharedUserId,
        isAuth,
        identityId,
        inactiveReason
      };
    }

    userAccount = await Auth.currentUserCredentials();
    identityId = userAccount.identityId;

    // @ts-ignore This type check is wrong.
    const userCogId = (await Auth.currentSession()).idToken.payload.sub;

    featureFlagConnection.setAttributes({
      id: userCogId
    });

    // @ts-ignore
    const profile = await getProfiles({ isAuth });

    if (Object.keys(profile).length !== 0) {
      profileName = profile.profileName ?? profileName;
      profileTrade = profile.profileTrade ?? profileTrade;
      phoneNumber = profile.phoneNumber ?? phoneNumber;
      paymentPlatformCustomerId = profile.paymentPlatformCustomerId ?? paymentPlatformCustomerId;
      paymentPlatform = profile.accountInfo.paymentPlatform ?? paymentPlatform;
      profileId = profile.id ?? profileId;
      founderState = profile.accountInfo.founderState ?? founderState;
      paymentExpiry = profile?.accountInfo?.paymentExpiry ? new Date(profile.accountInfo.paymentExpiry) : paymentExpiry;
      accountTier = profile?.accountInfo?.accountTier ?? accountTier;
      sharedUserId = profile.sharedUserId ?? sharedUserId;
      email = profile.email ?? email;
      inactiveReason = profile.accountInfo.inactiveReason ?? inactiveReason;

      // Do this check before the first render to ensure we have the info to redirect/block very early.
      defaultPlusMemberCheck = await checkIsPlusUser(profile.accountInfo);
    }
    else {
      console.log(`Failed to get Profile information ${JSON.stringify(profile)}`);
    }
    // @ts-ignore
    idTokenClaims = await getIdTokenClaims();
  }

  return { 
    accountTier,
    paymentExpiry,
    founderState,
    profileId,
    profileName,
    profileTrade,
    paymentPlatformCustomerId,
    paymentPlatform,
    phoneNumber,
    userAccount,
    defaultPlusMemberCheck,
    idTokenClaims,
    sharedUserId,
    isAuth,
    email,
    identityId,
    inactiveReason
  };
};
