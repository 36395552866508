import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  ReactNode
} from 'react';

import { captureMessage } from '@sentry/nextjs';

import { Amplify, Hub } from '@aws-amplify/core';
import { Cache } from '@aws-amplify/cache';
import { DataStore } from '@aws-amplify/datastore';
import { Auth } from '@aws-amplify/auth';

// @ts-ignore-next-line
import { ampli } from '../ampli';
import { useRouter } from 'next/router';
import { setUser as sentrySetUser } from '@sentry/nextjs';

import { getProfiles } from '@utilities/getProfiles';
import { checkAuth } from '@utilities/checkAuth';

import { syncUserSetup } from './syncUserSetup';

// @ts-ignore-next-line
import awsconfig from '@aws/aws-exports.js';
Amplify.configure(awsconfig);
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const IS_PROD = process.env.GATSBY_IS_PROD === 'true';

const SSR = typeof window === 'undefined';

interface User {
  [key: string]: any;
  signInUserSession?: { [key: string]: any };
  userModelAdded?: boolean;
  account: {
    accountTier: 'STANDARD' | 'PLUS';
    paymentExpiry: Date;
    founderState: boolean;
    profileId: string;
    paymentPlatformCustomerId: string;
    paymentPlatform: string;
    email: string;
    inactiveReason: 'NOT_APPLICABLE'| 'CANCELED' | 'EXPIRED' | 'PAUSED';
  };
}

interface UserContextProps {
  user: User | boolean;
  rate: number;
  signIn: null | Function;
  signOut: null | Function;
  isLoggedIn: null | boolean;
  updateProfile: null | Function;
  plusMember: boolean;
  expireTime: Date;
  hasExpired: boolean;
}

const {
  accountTier,
  paymentExpiry,
  founderState,
  paymentPlatformCustomerId,
  paymentPlatform,
  profileId,
  profileName,
  profileTrade,
  phoneNumber,
  userAccount,
  defaultPlusMemberCheck = false,
  idTokenClaims,
  sharedUserId,
  email = '',
  isAuth = false,
  identityId,
  inactiveReason
} =
  // @ts-ignore
  await syncUserSetup();

const defaultAccount = {
  accountTier,
  paymentExpiry,
  founderState,
  profileId,
  paymentPlatformCustomerId,
  paymentPlatform,
  email,
  inactiveReason
};

const UserContext = createContext<UserContextProps>({
  user: {
    account: defaultAccount,
    auth: {
      identityId
    }
  },
  rate: 60,
  signIn: () => console.log('Not set up'),
  signOut: () => console.log('Not set up'),
  isLoggedIn: isAuth,
  updateProfile: null,
  plusMember: defaultPlusMemberCheck,
  expireTime: paymentExpiry,
  // @ts-ignore
  hasExpired: paymentExpiry < Date.now()
});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<false | User>({
    profileName,
    profileTrade,
    phoneNumber,
    ...userAccount,
    auth: {
      identityId
    },
    // @ts-ignore
    idTokenClaims,
    account: defaultAccount
  });

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isAuth);
  const [plusMember, setPlusMember] = useState<boolean>(defaultPlusMemberCheck);
  const [expireTime, setExpireTime] = useState<Date>(paymentExpiry);
  const [userSetup, setUserSetup] = useState<boolean>(false);
  const [ampliUserSet, setAmpliUserSet] = useState<boolean>(false);
  let authListener = useRef<null | Function>(null);

  const router = useRouter();

  // Used to update profile content post app boot up - i.e. success page.
  const updateProfile = useCallback(
    async ({ isAuth, userData }: { isAuth: boolean; userData: any }) => {
      if (isLoggedIn || isAuth) {
        const { accountInfo: { founderState, paymentExpiry, accountTier, paymentPlatformCustomerId, paymentPlatform, email, inactiveReason } } = await getProfiles({ isAuth, retries: 5 });

        // If paymentExpiry is not epoch 0, this user has had a plus subscription
        setPlusMember(new Date(paymentExpiry).getTime() !== 0);
        setExpireTime(paymentExpiry);

        setUser((user) => ({
          ...user,
          ...userData,
          account: {
            accountTier,
            paymentExpiry: new Date(paymentExpiry),
            founderState,
            profileId,
            paymentPlatformCustomerId,
            paymentPlatform,
            email,
            inactiveReason
          },
          auth: {
            identityId
          }
        }));

      }
    },
    [isLoggedIn]
  );

  const signOut = useCallback(async () => {
    // Global signs the user out of all devices.
    await Auth.signOut({ global: false });

    setUser(false);
    setIsLoggedIn(false);
    setUserSetup(false);
    Cache.removeItem('publicUserId');
    window.location.replace('/login');
    window.location.reload();
  }, []);

  const signIn = useCallback(
    async ({ userData }: { userData: any }) => {
      // TODO: Investigate logic around datastore clearing vs logging in/out.
      // @ts-ignore-next-line Ignore private prop rule.
      if (DataStore.state !== 'Clearing') {
        await DataStore.clear();
        await DataStore.start();
      }

      await updateProfile({ isAuth: true, userData });
      setIsLoggedIn(true);
      // Force the user to the jobs page and trigger a re-render to update to a fresh state.
      window.location.replace(
        '/jobs'
      );
    },
    [updateProfile]
  );

  useEffect(() => {
    (async () => {
      if (isLoggedIn && router.pathname === '/login') {
        await router.push('/jobs');
      }
    })();
  }, [isLoggedIn, router]);

  // Hooks for the already signed in revisit flow.
  useEffect(() => {
    (async () => {
      const isAuth = SSR ? false : await checkAuth();
      if (!isAuth) {
        // Regular check.
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        setUserSetup(true);
      }

      if (sharedUserId) {
        // Update amplitude with the user ID from SQL.
        // TODO This will need to change to public user id in amplify (not sub);
        const userId = sharedUserId;
        if (!ampliUserSet) {
          sentrySetUser({ userId: `${userId}` });
          ampli.identify(userId);
          setAmpliUserSet(true);
          Cache.setItem('publicUserId', userId);
        }
      }

      if (!IS_PROD) console.log('User info: ', user, { isLoggedIn });
    })();
  }, [user, isLoggedIn, ampliUserSet, userSetup]);

  // The Hub listener has been refactored out as its unreliable.
  useEffect(() => {
    if (authListener.current === null) {
      authListener.current = Hub.listen('auth', async (data) => {
        switch (data.payload.event) {
        case 'signIn':
          break;

        case 'signUp':
          console.log('user signed up');
          break;

        case 'signOut':
          break;

        case 'signIn_failure':
          captureMessage('user sign in failed');
          break;

        case 'configured':
          break;

        default:
          console.log(`Unknown auth event ${data.payload.event}`);
        }
      });
    }

    return () => {
      if (authListener.current) authListener.current();
    };
  }, [isLoggedIn, router, user]);

  return (
    <UserContext.Provider
      value={{
        user,
        rate: 60,
        signIn,
        signOut,
        isLoggedIn,
        updateProfile,
        plusMember,
        expireTime,
        // @ts-ignore
        hasExpired: expireTime < Date.now()
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
