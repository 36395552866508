import { date } from "./date.js";
import { enrichYepicFields } from "./enrichYepicFields.js";
export const mergeVisits = ({
  yepics = [],
  places = [],
  tasks = [],
  images = [],
  visits
}) => {
  return visits.map(({ id, yepicID, start, ...rest }) => {
    const theYepic = yepics.find(({ id: id2 }) => id2 === yepicID) || {};
    return {
      id,
      dayTitle: date({ date: start }),
      // @ts-ignore-next-line
      yepic: enrichYepicFields(theYepic),
      place: places.find(({ id: id2 }) => id2 === theYepic?.placeID),
      images: images.filter(({ yepicID: yepicID2 }) => yepicID2 === theYepic.id),
      tasks: tasks.filter(({ yepicID: yepicID2 }) => yepicID2 === theYepic.id),
      start,
      ...rest
    };
  });
};
