import { useEffect, useState } from 'react';
import { NextRouter } from 'next/router';

export const useSafeRouterPush = ({ router, condition, url } : { router: NextRouter, condition: boolean, url: string} ) => {
  const [onChanging, setOnChanging] = useState(false);
  const handleRouteChange = () => {
    setOnChanging(false);
  };

  useEffect(() => {
    if (!onChanging && condition && router.pathname !== url) {
      setOnChanging(true);
      router.push(url);
    }

  // This is dynamic so we need to ignore the rule.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChanging, condition]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setOnChanging]);
};
