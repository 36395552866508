import { GrowthBook } from '@growthbook/growthbook';

const features = [
  'desktop--payment-links',
  'desktop--debugging',
  'desktop--restrict-page-access'
] as const;

type Features = typeof features[number];

let featureFlagConnection = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-es4s278zozi9UDLN',
  //decryptionKey: process.env.GROWTHBOOK_DECRYPT_KEY,
  enableDevMode: true,
  subscribeToChanges: true,
  // Disable background streaming connection - to reduce costs.
  backgroundSync: false,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});

// @ts-ignore Top level awaits in modules are allowed.
await featureFlagConnection.loadFeatures();

const isEnabled = ({ featureName } : { featureName: Features }) => {
  if (features.includes(featureName)) {
    return featureFlagConnection?.getFeatureValue(featureName, false) || false;
  }
  else {
    throw new Error(`Unknown feature: ${featureName}`);
  }
};

const checkPath = ({ path } : { path: string }) => {
  const config = featureFlagConnection?.getFeatureValue('desktop--restrict-page-access', false) || false;
  // Fallback to allowing access - this is for when the feature is removed/disabled.
  if (!config) return true;

  // @ts-ignore
  return config[path] || true;
};

export {
  features,
  featureFlagConnection,
  isEnabled,
  checkPath
}; 