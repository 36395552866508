import { Auth } from '@aws-amplify/auth';

export const getValidToken = async (): Promise<string> => {
  return await new Promise((yes) => {
    Auth.currentSession()
      .then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration();
        const refreshToken = session.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err: any, data: any) => {
                if (err) {
                  Auth.signOut();
                } else {
                  yes(data.getIdToken().getJwtToken());
                }
              });
            });
        } else {
          yes(session.getIdToken().getJwtToken());
        }
      })
      .catch(() => {
        // No logged-in user: don't set token.
      });
  });
};
