import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from 'react';

import { Amplify, Hub } from '@aws-amplify/core';
import { DataStore } from '@aws-amplify/datastore';

import {
  Yepic,
  YepicPlace,
  Task,
  Image,
  YepicVisit,
  Note
} from '@models';

import { mergeVisits, mergeYepics } from 'yepic-helpers';
import { setupStateUpdater } from '@utilities/setupStateUpdater';
import awsconfig from '@aws/aws-exports.js';

Amplify.configure(awsconfig);

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const IS_PROD = process.env.GATSBY_IS_PROD === 'true';

const DataContext = createContext({
  jobs: [],
  visits: [],
  notes: [],
  images: [],
  places: [],
  dataReady: null
});

export const useDataContext = () => {
  return useContext(DataContext);
};


export const DataContextProvider = ({ children }) => {
  const [jobs, setJob] = useState({ready: false});
  const [places, setPlace] = useState({ready: false});
  const [tasks, setTasks] = useState({ready: false});
  const [images, setImages] = useState({ready: false});
  const [visits, setVisits] = useState({ready: false});
  const [notes, setNotes] = useState({ready: false});
  const [dataReady, setDataReady] = useState(null);
  const [listenersAdded, setListenersAdded] = useState(false);
  const [hubListenersAdded, setHubListenersAdded] = useState(false);
  const [failedImages, setFailedImages] = useState([]);
  const dataStoreStatus = useRef(DataStore.state);
  const [stateDataStatus, setStateDataStatus] = useState('notStarted');

  const [data, setData] = useState({
    jobs: [],
    visits: [],
    photos: []
  });

  const markImageAsFailed = useCallback(({ image }) => {
    setFailedImages((prev) => [...prev, image.id]);
  }, []);

  const stopDataStore = useCallback(async () => {
    setDataReady(false);
    setStateDataStatus('clearingStore');
    // await DataStore.clear();
    setStateDataStatus('stoppingStore');
    await DataStore.stop();
    setStateDataStatus('notRunning');
  }, []);

  const startDataStore = useCallback(async () => {
    if (!IS_PROD) console.log('Attempting to start the DataStore.', DataStore.state);
    if (!hubListenersAdded) {
      Hub.listen('datastore', (message) => {
        const { payload } = message;

        if (!IS_PROD) {
          console.log('DataStore Event: ', payload.event);
        }
        if (
          ![
            'networkStatus',
            'modelSynced',
            'syncQueriesReady',
            'subscriptionsEstablished',
            'storageSubscribed',
            'outboxStatus',
            'syncQueriesStarted'
          ].includes(payload.event)
        ) {
          dataStoreStatus.current = payload.event;

          if (stateDataStatus !== payload.event && payload.event !== null) {
            setStateDataStatus(payload.event);
          }
        }
      });

      setHubListenersAdded(true);
    }

    if (!listenersAdded && dataStoreStatus.current !== 'Not running') {
      setupStateUpdater({ model: Yepic, setState: setJob, name: 'yepic' });
      setupStateUpdater({ model: YepicPlace, setState: setPlace, name: 'yepicPlace' });
      setupStateUpdater({ model: Task, setState: setTasks, name: 'task' });
      setupStateUpdater({ model: YepicVisit, setState: setVisits, name: 'yepicVisit' });
      setupStateUpdater({
        model: Image,
        setState: setImages,
        filterFn: (item) =>
          item._deleted === null
          && item.syncStatus === 'SYNCED' || item.syncStatus === null,
        name: 'image'
      });
      setupStateUpdater({ model: Note, setState: setNotes, name: 'note' });
      // Ensure the listeners only get added once.
      setListenersAdded(true);
    }
  }, [hubListenersAdded, listenersAdded, stateDataStatus]);

  useEffect(() => {
    if (!IS_PROD) {
      console.log(
        { jobs, places, tasks, images, visits, notes }
      );
    }
    if (![jobs.ready, places.ready, tasks.ready, images.ready, visits.ready].includes(false) && dataReady !== false) {
      const updatedImages = images.content.map(image => {
        return {
          ...image,
          failed: failedImages.includes(image.id)
        };
      });
      // Data is sync'ed and ready to merge.
      setData({
        jobs: mergeYepics({
          yepics: jobs.content,
          places: places.content,
          tasks: tasks.content,
          images: updatedImages,
          visits: visits.content,
          notes: notes.content
        }),
        visits: mergeVisits({
          yepics: jobs.content,
          places: places.content,
          tasks: tasks.content,
          images: updatedImages,
          visits: visits.content
        }),
        photos: updatedImages
      });

      if (!IS_PROD) console.log('Data has been loaded into state.');
      if (dataReady === null) {
        setDataReady(true);
      }
    }

  }, [jobs, places, notes, tasks, images, visits, failedImages, dataReady]);

  useEffect(() => {
    if (!IS_PROD) console.log({stateDataStatus, jobs, places, notes, tasks, images, visits, failedImages, dataReady, listenersAdded});
    // This is done outside of the Hub events as we don't want to rely on the data loading events.
    // We only care about when we've had the first page of data as the system can handle merging
    // incoming content over the stale/old/missing and we want to enable the user to explore asap.
    if (listenersAdded && stateDataStatus !== 'dataLoading' && stateDataStatus === 'notStarted') {
      setStateDataStatus('dataLoading');
    }
  }, [dataReady, failedImages, images, jobs, listenersAdded, notes, places, stateDataStatus, tasks, visits]);


  return (
    <DataContext.Provider value={{ ...data, dataReady, startDataStore, markImageAsFailed, stateDataStatus, stopDataStore }}>
      {children}
    </DataContext.Provider>
  );
};
