// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ImageSyncStatus = {
  "LOCAL_ONLY": "LOCAL_ONLY",
  "SYNCED": "SYNCED",
  "MISSING": "MISSING"
};

const PaymentPlatform = {
  "APPLE": "APPLE",
  "GOOGLE": "GOOGLE",
  "STRIPE": "STRIPE",
  "NONE": "NONE"
};

const AccountTier = {
  "PLUS": "PLUS",
  "STANDARD": "STANDARD"
};

const InactiveReason = {
  "NOT_APPLICABLE": "NOT_APPLICABLE",
  "CANCELED": "CANCELED",
  "EXPIRED": "EXPIRED",
  "PAUSED": "PAUSED"
};

const SharedUserRole = {
  "FULL": "FULL",
  "BASIC": "BASIC"
};

const { YepicPlace, Yepic, Image, ImageNote, YepicVisit, VisitNote, Note, Task, TaskNote, Stop, OpenVisit, AnalyticProperties, Profile, Contact, SharedJob, ImageLocation, AccountInfo, JobByUser } = initSchema(schema);

export {
  YepicPlace,
  Yepic,
  Image,
  ImageNote,
  YepicVisit,
  VisitNote,
  Note,
  Task,
  TaskNote,
  Stop,
  OpenVisit,
  AnalyticProperties,
  Profile,
  Contact,
  SharedJob,
  ImageSyncStatus,
  PaymentPlatform,
  AccountTier,
  InactiveReason,
  SharedUserRole,
  ImageLocation,
  AccountInfo,
  JobByUser
};