import { Auth } from '@aws-amplify/auth';

let isAuth: boolean = false;

export const checkAuth = async () => {
  if (isAuth !== true) {
    try {
      isAuth = await Auth.currentAuthenticatedUser() ? true : false;
    }
    catch(err) {
      isAuth = false;
    }
  }

  return isAuth;
};