import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

import { featureFlagConnection, isEnabled } from '@utilities/featureFlagging';

import { useFeatureValue } from '@growthbook/growthbook-react';

const contextDefault = {
  paymentLinksIsEnabled: isEnabled({ featureName: 'desktop--payment-links' }),
  features: {
    paymentLinks: {
      enabled: false
    },
    debugging: {
      enabled: false
    },
    pageAccess: {}
  },
  // eslint-disable-next-line no-unused-vars
  isBlocked: ({ path } : {path: string}) => true,
  blockedPaths: [] as string[]
};

const FeatureFlagContext = createContext(contextDefault);
export const useFeatureFlagContext = () => {
  return useContext(FeatureFlagContext);
};

export const FeatureFlagContextProvider = ({ children }: { children: ReactNode }) => {
  const [features, setFeatures] = useState(contextDefault.features); 
  const [checkedGrowthBook, setCheckedGrowthBook] : [paymentLinks: boolean, setPaymentLinks: Function] = useState(false);

  const navigationAccess = useFeatureValue('desktop--restrict-page-access', {
    jobs: true,
    time: true,
    photos: true
  });

  const blockedPaths = Object.entries(navigationAccess).filter(([, value]) => !value).map(([key]) => `/${key}`);

  useEffect(() => {
    // Boot up feature flag checking.
    (async () => {
      if (checkedGrowthBook === false && featureFlagConnection) {
        setCheckedGrowthBook(true);

        let featureSettings = { ...features };
        featureSettings.debugging.enabled = isEnabled({ featureName: 'desktop--debugging' });
        featureSettings.paymentLinks.enabled = isEnabled({ featureName: 'desktop--payment-links' });
        featureSettings.pageAccess = isEnabled({ featureName: 'desktop--restrict-page-access' });

        setFeatures(featureSettings);
      }
    })();
  }, [checkedGrowthBook, features]);
    
  return (
    <FeatureFlagContext.Provider value={{
      features,
      paymentLinksIsEnabled: features.paymentLinks.enabled,
      isBlocked: ({path}) => blockedPaths.includes(path),
      blockedPaths
    }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
