import { enrichYepicFields } from "./enrichYepicFields.js";
export const mergeYepics = ({
  yepics,
  places = [],
  tasks = [],
  images = [],
  visits = [],
  notes = []
}) => {
  return yepics.map(({ id, placeID, title, ...rest }) => {
    const jobVisits = visits.filter(({ yepicID }) => yepicID === id);
    return {
      ...enrichYepicFields({
        places,
        placeID,
        title,
        id,
        // TODO: Investigate this type issue.
        // @ts-ignore-next-line
        visits: jobVisits,
        ...rest
      }),
      images: images.filter(({ yepicID }) => yepicID === id),
      tasks: tasks.filter(({ yepicID }) => yepicID === id),
      visits: jobVisits,
      notes: notes.filter(({ yepicID }) => yepicID === id)
    };
  });
};
