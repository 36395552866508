import React, { useEffect } from 'react';

import { GrowthBookProvider } from '@growthbook/growthbook-react';

import { Cache } from '@aws-amplify/cache';

import { IntercomProvider } from 'react-use-intercom';

import { AuthWrapper } from '../components/atoms/auth';
import { Analytics } from '../components/atoms/analytics';

import { UserContextProvider } from '@context/user';
import { DataContextProvider } from '@context/data';
import { FeatureFlagContextProvider } from '@context/featureFlags';
import { ModalContextProvider } from '../components/molecules/modal';

import { featureFlagConnection } from '@utilities/featureFlagging';


// NextJS forces you to have global css imported at the root.
import '../components/atoms/select/react-select-overrides.scss';
import '../components/molecules/quoteSlider/swiper-global-styles.scss';
import '../components/organisms/photoCarousel/photoCarousel.global.scss';

import '../hooks/toastStyles.scss';
import '../csrPages/photos/globals.scss';
import '@scss/global.scss';
import '@scss/oneTrust.scss';

const isServer = typeof window === 'undefined';

// Ensure users get a fresh cache from code after 20 June 2023.
const flushCacheFrom = '1687248547';

const intercomAppId = 'tpsqvvmy';


const App = ({ Component, pageProps }) => {

  useEffect(() => {
    const lastClear = Cache.getItem('CacheForceClearDateTime');

    // Used to force cache flushes.
    if (lastClear !== flushCacheFrom) {
      console.log('Clearing cache.');
      Cache.clear();
      Cache.setItem('CacheForceClearDateTime', flushCacheFrom);
    }

  }, []);

  // Remove auth and analytics from SSR.
  if (isServer) {
    return (
      <>
        <UserContextProvider>
          <Analytics>
            <DataContextProvider>
              <ModalContextProvider>
                <IntercomProvider appId={intercomAppId}>
                  <Component {...pageProps} />
                </IntercomProvider>
              </ModalContextProvider>
            </DataContextProvider>
          </Analytics>
        </UserContextProvider>
      </>
    );
  }

  return (
    <>
      <UserContextProvider>
        <Analytics>
          <AuthWrapper>
            <GrowthBookProvider growthbook={featureFlagConnection}>
              <FeatureFlagContextProvider>
                <DataContextProvider>
                  <ModalContextProvider>
                    <IntercomProvider appId={intercomAppId}>
                      <Component {...pageProps} />
                    </IntercomProvider>
                  </ModalContextProvider>
                </DataContextProvider>
              </FeatureFlagContextProvider>
            </GrowthBookProvider>
          </AuthWrapper>
        </Analytics>
      </UserContextProvider>
    </>
  );
};

export default App;
