import React from 'react';
import { GoogleAnalytics } from '@next/third-parties/google';

if (typeof process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID === 'undefined') {
  console.log('Environment is missing NEXT_PUBLIC_GA_MEASUREMENT_ID');
}

// Ensure NEXT_PUBLIC_GA_MEASUREMENT_ID is set.

export const Analytics = ({ children }) => {
  return (
    <>
      <GoogleAnalytics gaId="GTM-N7DQZSX6" />
      {children}
    </>
  );
};
