import { formatAddress } from "./formatAddress.js";
import { fallbackTitle } from "./labels.js";
const enrichYepicFields = ({
  places = [],
  placeID,
  title,
  id,
  visits = [],
  ...rest
}) => {
  const { formattedAddress = "", ...placeObj } = (
    // @ts-ignore-next-line
    places.find(({ id: searchedPlaceId }) => searchedPlaceId === placeID) || {}
  );
  const address = formatAddress({
    rawAddress: formattedAddress
  });
  return {
    title: title || fallbackTitle,
    yepicID: id,
    lastVisit: visits.sort(({ start: startA }, { start: startB }) => +new Date(startB) - +new Date(startA))[0],
    place: {
      ...placeObj,
      address
    },
    ...rest
  };
};
export { enrichYepicFields };
