import isYesterday from "date-fns/isYesterday/index.js";
import isToday from "date-fns/isToday/index.js";
import formatFn from "date-fns/format/index.js";
import {
  dateDayMonth,
  dayMonth,
  dayMonthYear,
  dateFullMonthYear,
  dateFullMonthYearTime,
  dateMonthYearTimeSeconds,
  monthYear,
  monthDateDayTime
} from "./formats.js";
export const date = ({ date: date2, format = "dateDayMonth", smart = true }) => {
  let dateObj = new Date();
  let theFormat = null;
  try {
    dateObj = new Date(date2);
  } catch (err) {
    console.error("Error processing date ", date2);
    return "";
  }
  if (isToday(dateObj) && smart) {
    return "Today";
  }
  if (isYesterday(dateObj) && smart) {
    return "Yesterday";
  }
  switch (format) {
    case "dateMonthYearTimeSeconds":
      theFormat = dateMonthYearTimeSeconds;
      break;
    case "dateMonthYearTime":
      theFormat = dateFullMonthYearTime;
      break;
    case "dateFullMonthYear":
      theFormat = dateFullMonthYear;
      break;
    case "dateDayMonthYear":
      theFormat = dayMonthYear;
      break;
    case "dateDayMonth":
      theFormat = dateDayMonth;
      break;
    case "dayMonth":
      theFormat = dayMonth;
      break;
    case "monthYear":
      theFormat = monthYear;
      break;
    case "monthDateDayTime":
      theFormat = monthDateDayTime;
      break;
    default:
      theFormat = dateDayMonth;
  }
  return formatFn(dateObj, theFormat).replace(/\s/g, "\xA0");
};
