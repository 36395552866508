import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { Profile } from '@models';

import { checkAuth } from './checkAuth';
import { getValidToken } from './getValidToken';

let retrying = false;


export const getProfiles = async ({ isAuth, retries = 10 }: { isAuth?: boolean, retries?: number }): Promise<{ [key: string]: any }> => {
  // Skip this if we're SSR or the user isn't authenticated.
  if (typeof window !== 'undefined' && (isAuth || await checkAuth())) {

    const { payload: { profile_id, shared_user_id } } = (await Auth.currentSession()).getIdToken();

    // Stops retries from stacking.
    if (!retrying) {
      retrying = true;
      const res = await fetchProfile(profile_id);

      const { data: { profileTrade, profileName, username } } = res;

      if (res.found) {
        retrying = false;
        return {
          ...res.data,
          profileTrade: profileTrade ? profileTrade : 'Trade',
          profileName: profileName ? profileName : 'Name',
          sharedUserId: shared_user_id,
          phoneNumber: username
        };
      }

      return getProfiles({ isAuth, retries: retries - 1 });
    }
  }

  return {};

};

const fetchProfile = async (profileId: string) => {
  const profiles = await API.graphql<GraphQLQuery<Profile>>(
    {
      authToken: await getValidToken(),
      query: /* GraphQL */ `query GETPROFILE {
        getProfile(id: "${profileId}") {
          id
          profileTrade
          profileName
          username
          email
          paymentPlatformCustomerId
          accountInfo {
            accountTier
            founderState
            paymentExpiry
            paymentPlatform
            inactiveReason
          }
        }
      }`
    }
  );

  // @ts-ignore
  if (typeof profiles?.data?.getProfile === 'undefined') {
    return { found: false, data: {} };
  }

  // @ts-ignore
  return { found: true, data: profiles.data.getProfile };
};