import { captureMessage, setExtra } from '@sentry/nextjs';

import { getValidToken } from './getValidToken';


// jwt structure looks like: encodedHeader.encodedBody.signature
export const getIdTokenClaims = async (idToken?: string) => {
  try {
    const jwt = idToken || await getValidToken();
    return JSON.parse(window.atob(jwt.split('.')[1]));  
  } catch (err) {
    setExtra('errors', `${JSON.stringify(err)}`);
    captureMessage('Failed to get claims from idToken', 'error');

    return {};
  }
};