import React, { useLayoutEffect } from 'react';
import { useRouter } from 'next/router';

import { useUserContext } from '@context/user';

import { useSafeRouterPush } from '@hooks/useSafeRouterPush';

import { publicUrls } from '@utilities/publicUrls';
import { checkAuth } from '@utilities/checkAuth';

export const AuthWrapper = ({ children }) => {
  const { isLoggedIn, user } = useUserContext();
  const router = useRouter();

  useSafeRouterPush({
    url: '/jobs', 
    router, 
    triggers: [router],
    // Check if the user is a PLUS member and their subscription payment hasn't expired.
    // Also ensure this doesn't happen on public pages.
    condition: router.pathname === '/login' && isLoggedIn
  });

  useLayoutEffect(() => {
    (async () => {
      const url = router.pathname;
      const isAuth = await checkAuth();

      // The user has been actively marked as not logged in - go to login page.
      if (isAuth === false && !publicUrls.includes(url)) {
        //await router.push('/login');
        window.location.replace(
          '/login'
        );
      }
      
    })();
  }, [isLoggedIn, router, router.pathname, user]);


  if (isLoggedIn === false && !publicUrls.includes(router.pathname)) {
    return <></>;
  }

  return <>{children}</>;
};
