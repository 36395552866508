import React, {
  useRef,
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback
} from 'react';

import classNames from 'classnames';

import { useRouter } from 'next/router';

import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';

import * as styles from './styles.module.scss';

export const Modal = ({ children }) => {
  const router = useRouter();
  const { isOpen, content, closeModal, stylingOptions } = useModalContext();
  const modalRef = useRef(null);
  const bodyRef = useRef(null);

  useEffect(() => {
    if (modalRef.current === null) {
      bodyRef.current = document.body;
    }
  }, []);

  const onActivate = () => {
    modalRef.current.classList.add('active');
  };

  const onDeactivate = () => {
    modalRef.current.classList.remove('active');
  };

  const resetBodyClass =  useCallback(() => {
    closeModal(false);
    bodyRef.current.classList.remove('modal-active');
  }, [bodyRef, closeModal]);

  useEffect(() => {
    if (router) {
      router.events.on('routeChangeStart', resetBodyClass);
      return () => router.events.off('routeChangeStart', resetBodyClass);
    }
  }, [resetBodyClass, router]);

  return (
    <>
      {bodyRef.current &&
        isOpen &&
        createPortal(
          <FocusTrap
            focusTrapOptions={{
              onActivate,
              onDeactivate,
              clickOutsideDeactivates: true
            }}
          >
            <div className={classNames(
              styles.modal,
              stylingOptions.solidBackground ? styles.solidBackground : styles.opaqueBackground,
              stylingOptions.offsetForSidebar ? styles.offsetForSidebar : ''
            )
            } ref={modalRef}>
              <div>
                {children}
                {content}
              </div>
            </div>
          </FocusTrap>,
          bodyRef.current
        )}
    </>
  );
};

export const ModalControl = ({ children, modalContent }) => {
  const { openModal, setContent } = useModalContext();

  const handleChange = useCallback(() => {
    setContent(modalContent);
    openModal(modalContent);
  }, [modalContent, openModal, setContent]);

  return (
    <button onClick={handleChange} className="button">
      {children}
    </button>
  );
};

const defaultStylingOptions = {
  solidBackground: true,
  offsetForSidebar: true
};

export const ModalContext = createContext({
  isOpen: false,
  stylingOptions: {
    ...defaultStylingOptions
  },
  setStylingOptions: () => {},
  closeModal: () => {},
  openModal: () => {}
});

export const useModalContext = () => {
  return useContext(ModalContext);
};

export const ModalContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [stylingOptions, setStylingOptions] = useState(defaultStylingOptions);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (typeof document.body !== 'undefined' && isOpen) {
      document.body.classList.add('modal-active');
    }

    if (typeof document.body !== 'undefined' && !isOpen) {
      document.body.classList.remove('modal-active');
    }

    return () => document.body.classList.remove('modal-active');
  }, [isOpen]);

  return (
    <ModalContext.Provider
      value={{ isOpen, openModal, closeModal, setContent, content, stylingOptions, setStylingOptions }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalContainer = ({ children }) => {
  return (
    <div className={styles.modalContainer}>
      {children}
    </div>
  );
};
