import { titleCase } from "./titleCase.js";
export const formatAddress = ({ rawAddress = "" }) => {
  let rawPostCode = null;
  let isPostCode = false;
  let postCode = "";
  let attemptToRemovePostCode = "";
  if (rawAddress === "") {
    return false;
  }
  const rawAddressParts = rawAddress.split(", ");
  if (rawAddressParts.length > 0) {
    rawPostCode = rawAddressParts.slice(-1).at(0)?.toUpperCase();
  }
  if (typeof rawPostCode === "string") {
    isPostCode = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(rawPostCode);
  }
  if (isPostCode) {
    attemptToRemovePostCode = rawAddressParts.slice(0, -1).join(", ");
  }
  const address = titleCase({
    text: isPostCode ? attemptToRemovePostCode : rawAddress
  });
  if (rawPostCode && isPostCode) {
    postCode = ` ${rawPostCode}`;
  }
  return `${address}${postCode}`;
};
