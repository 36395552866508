import { Amplify } from '@aws-amplify/core';
import { DataStore } from '@aws-amplify/datastore';
const IS_PROD = process.env.GATSBY_IS_PROD === 'true';

// @ts-ignore-next-line
import awsconfig from '@aws/aws-exports.js';
Amplify.configure(awsconfig);

const setupStateUpdater = async ({
  setState,
  model,
  filterFn = () => true,
  name
} : { setState: Function, model: any, filterFn: Function, name: string }) => {
  const observer = DataStore.observeQuery(model);
  observer.subscribe((snapshot: { [key: string]: any }) => {
    const { items, isSynced } = snapshot;
    const content = items.filter(filterFn);
    if (!IS_PROD) {
      console.log(
        `[Snapshot] ${name} item count: ${items.length}, isSynced: ${isSynced}`,
        // @ts-ignore
        DataStore.state
      );
    }
    setState({
      content,
      isSynced,
      ready: content.length > 0 || isSynced
    });
  });
};

export {
  setupStateUpdater
};