/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull yepic_desktop'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/yepic/Yepic/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/yepic/Yepic/implementation/yepic_desktop)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: '020e820c77d3e1a02eb770a67b851753'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'yepic_desktop',
    versionId: '82b5c80c-6686-4e36-ae6b-f9f9656db8fe'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface ButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  button_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  button_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  button_target_url?: string;
  button_type?: string;
}

export interface EnvironmentProperties {
  value: string;
}

export interface LinkClickProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  link_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  link_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  link_target_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  link_type?: string;
}

export interface ReportCreationProperties {
  creation_time: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  image_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  job_ids?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  note_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | jobReport, filteredJobReport, photoReport, jobPhotoReport, tasksReport, notesReport, jobTimeSheet, usersTimeSheet |
   */
  report_type:
    | "jobReport"
    | "filteredJobReport"
    | "photoReport"
    | "jobPhotoReport"
    | "tasksReport"
    | "notesReport"
    | "jobTimeSheet"
    | "usersTimeSheet";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  task_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  visit_count: number;
}

export interface YepicErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  error_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  error_type?: string;
}

export class ButtonClicked implements BaseEvent {
  event_type = 'button_clicked';

  constructor(
    public event_properties?: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Environment implements BaseEvent {
  event_type = 'environment';

  constructor(
    public event_properties: EnvironmentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LinkClick implements BaseEvent {
  event_type = 'link_click';

  constructor(
    public event_properties?: LinkClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReportCreation implements BaseEvent {
  event_type = 'report_creation';

  constructor(
    public event_properties: ReportCreationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class YepicError implements BaseEvent {
  event_type = 'yepic_error';

  constructor(
    public event_properties?: YepicErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * button_clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/yepic/Yepic/events/main/latest/button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. button_location)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties?: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * environment
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/yepic/Yepic/events/main/latest/environment)
   *
   * Used to filter out test environments
   *
   * @param properties The event's properties (e.g. value)
   * @param options Amplitude event options.
   */
  environment(
    properties: EnvironmentProperties,
    options?: EventOptions,
  ) {
    return this.track(new Environment(properties), options);
  }

  /**
   * link_click
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/yepic/Yepic/events/main/latest/link_click)
   *
   * User clicks on a url link
   *
   * @param properties The event's properties (e.g. link_location)
   * @param options Amplitude event options.
   */
  linkClick(
    properties?: LinkClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkClick(properties), options);
  }

  /**
   * report_creation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/yepic/Yepic/events/main/latest/report_creation)
   *
   * Owner: James Drawneek
   *
   * @param properties The event's properties (e.g. creation_time)
   * @param options Amplitude event options.
   */
  reportCreation(
    properties: ReportCreationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportCreation(properties), options);
  }

  /**
   * yepic_error
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/yepic/Yepic/events/main/latest/yepic_error)
   *
   * Event to capture errors within yepic.
   *
   * In the app currently only errors related to entering an invalid phone number (error_type = signup), and entering an invalid otp code (\*error_type = verification) are tracked
   *
   * Owner: James Drawneek
   *
   * @param properties The event's properties (e.g. error_name)
   * @param options Amplitude event options.
   */
  yepicError(
    properties?: YepicErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new YepicError(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
