var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.3.11"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { addGlobalEventProcessor, init } from '@sentry/nextjs';
import { Cache } from '@aws-amplify/cache';

import { ampli } from './src/ampli';

import info from '../package.json' assert { type: 'json' };

ampli.load({ environment: 'default' });


const IS_PROD = process.env.IS_PROD === 'true';

let ampliUserSet = false;

init({
  dsn: 'https://442ca0c069dc48b49f14c666a7813dc0@o457244.ingest.sentry.io/4504576401866752',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  enabled: typeof process.env.IS_LOCAL === 'undefined',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  environment: process.env.ENVIRONMENT,
  release: info.version

  //replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  // integrations: [
  // new Sentry.Replay({
  //   // Additional Replay configuration goes in here, for example:
  //   maskAllText: true,
  //   blockAllMedia: true,
  // }),
//],
});


addGlobalEventProcessor(async (event) => {

  const publicId = Cache.getItem('publicUserId');

  if (publicId && !ampliUserSet) {
    // Update amplitude with the user ID from SQL.
    // TODO This will need to change to public user id in amplify (not sub);
    ampli.environment({ value: process.env.ENVIRONMENT || 'local' });
    ampli.identify(publicId);
    ampliUserSet = true;
  }

  if (event?.exception?.values?.length && event.exception.values.length > 0) {

    await Promise.all(event.exception.values.map(({ type, value }) => 
      ampli.yepicError(
        { 
          error_name: `${value}`, 
          error_type: `${type}`
        }
      )
    ));
  }
  
  return event;
});
